<template>
    <div class="home">
        <!--        <img alt="Vue logo" src="../assets/logo.png">-->
        <div class="actions">
            <button @click="add">Add</button>
            <button @click="resetAll" style="margin-left: 15px;">Reset All</button>
            <button @click="selectedTabIndex = null" style="margin-left: 15px;">STOP</button>
        </div>
        <div class="total-time">Суммарное время учтено: <span>{{ totalTime }}</span></div>
        <div class="tabs">
            <Tab :class="{active: selectedTabIndex === tabIndex}" :key="tab.name" :tab="tab" v-for="(tab, tabIndex) in tabs" @delete="deleteTab(tabIndex)" @select="selectTab(tabIndex)"/>
        </div>
        <div class="current-timing">{{ currentTime }}</div>
        <table>
            <tr v-for="(history, historyIndex) in histories">
                <td>{{ history.date }}</td>
                <td><strong>{{ history.tab.name }}</strong></td>
                <td contenteditable="true" @input="changeText(historyIndex, $event)">{{ history.tab.text }}</td>
            </tr>
        </table>

    </div>
    <div class="modal-speak" v-if="showSpeakModal" @click="showSpeakModal = false">ГОВОРИТЕ!</div>
    <div class="version">v1.02</div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/components/Tab.vue'
import moment from "moment";

export default {
    name: 'HomeView',
    components: {
        Tab
    },
    data() {
        return {
            selectedTabIndex: null,
            tabs: [],
            startTime: 0,
            histories: [],
            showSpeakModal: false,
        }
    },
    computed: {
        totalTime() {
            let totalTime = 0;

            this.tabs.forEach(tab => {
                totalTime += tab.time;
            })

            return new Date(totalTime * 1000).toISOString().slice(11, 19);

        },
        currentTime() {
            if (this.selectedTabIndex !== null) {
                return new Date(this.tabs[this.selectedTabIndex].time * 1000).toISOString().slice(11, 19);
            }

            return '--/--'
        }
    },
    created() {
        const tabs = localStorage.getItem('tabs');
        const startTime = localStorage.getItem('startTime');
        const selectedTabIndex = localStorage.getItem('selectedTabIndex');
        const histories = localStorage.getItem('histories');

        if (tabs) this.tabs = JSON.parse(tabs)
        if (startTime) this.startTime = startTime;
        if (selectedTabIndex) this.selectedTabIndex = JSON.parse(selectedTabIndex);
        if (histories) this.histories = JSON.parse(histories);
    },
    mounted() {
        setInterval(() => {
            const ts = moment().unix();
            if (this.selectedTabIndex !== null) {
                this.tabs[this.selectedTabIndex].time += ts - this.startTime;
            }
            this.startTime = ts;
            localStorage.setItem('startTime', ts)
        }, 1000);

        setInterval(() => {
            this.saveTabs();
        }, 1000);
    },
    methods: {
        changeText(historyIndex, e) {
            this.histories[historyIndex].tab.text = e.target.innerText;
        },
        saveTabs() {
            localStorage.setItem('tabs', JSON.stringify(this.tabs))
        },
        resetAll() {
            if (confirm('Reset?')) {
                this.tab = this.tabs.map((tab) => {
                    tab.time = 0;
                    return tab;
                })

                this.startTime = null;
                this.selectedTabIndex = null;
                this.histories = [];
            }
        },
        add() {
            let name = prompt('Новое');
            let price = prompt('Ставка за час');

            if (name) {
                this.tabs.push({
                    name: name,
                    title: name,
                    time: 0,
                    rate: price,
                    text: '',
                });

                this.saveTabs();
            }
        },
        selectTab(e) {
            this.selectedTabIndex = e;

            this.histories.unshift({
                date: moment().format('YYYY-MM-DD HH:mm:ss'),
                tab: Object.assign({}, {...this.tabs[this.selectedTabIndex], text: ''})
            });

            this.startRecognize();
        },
        startRecognize() {
            // Создаем распознаватель
            const recognizer = new webkitSpeechRecognition();

            // Ставим опцию, чтобы распознавание началось ещё до того, как пользователь закончит говорить
            recognizer.interimResults = true;

            // Какой язык будем распознавать?
            recognizer.lang = 'ru-Ru';

            // Используем колбек для обработки результатов
            recognizer.onresult = event => {
                const result = event.results[event.resultIndex];

                if (result.isFinal) {
                    const h = [...this.histories];
                    h[0].tab.text = result[0].transcript;
                    this.histories = h;

                    this.showSpeakModal = false;
                } else {
                    console.log('Промежуточный результат: ', result[0].transcript);
                }
            };

            // Начинаем слушать микрофон и распознавать голос
            recognizer.start();

            this.showSpeakModal = true;
        },
        deleteTab(tabIndex) {
            if (confirm('Удалить?')) {
                this.startTime = null;
                this.selectedTabIndex = null;
                this.tabs.splice(tabIndex, 1)
                localStorage.setItem('tabs', JSON.stringify(this.tabs))
            }
        }
    },
    watch: {
        selectedTabIndex(selectedTabIndex) {
            if (selectedTabIndex === null) {
                this.startTime = null;
            }
            localStorage.setItem('selectedTabIndex', JSON.stringify(selectedTabIndex))
        },
        histories: {
            handler(histories) {
                localStorage.setItem('histories', JSON.stringify(histories))
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .tab {
        margin-bottom: 15px;
    }
}

.actions {
    margin-bottom: 20px;

    button {
        @media (max-width: 768px) {
            font-size: 0.9rem;
        }
    }
}

.total-time {
    margin-bottom: 30px;
    color: #777777;

    span {
        font-weight: bold;
    }
}

button {
    background: #00e5e5;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 30px;
    font-size: 20px;
    cursor: pointer;
}

.current-timing {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    margin-top: 50px;
    font-weight: bold;

    @media (max-width: 1024px) {
        font-size: 40px;
        margin-top: 5px;
    }
}

table {
    width: 100%;
    max-width: 700px;
    border: 1px solid #cccccc;
    border-bottom: 0;
    border-right: 0;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: left;
    margin: 20px auto;


    @media (max-width: 1024px) {
        font-size: 0.7rem;
    }

    tr {
        border-bottom: 1px solid #cccccc;
        vertical-align: top;

        td {
            border-right: 1px solid #cccccc;
            padding: 15px 15px;
            outline: none;

            @media (max-width: 1024px) {
                padding: 5px;
            }

            &:first-child {
                width: 180px;

                @media (max-width: 1024px) {
                    width: 100px;
                }
            }
        }

    }
}

.modal-speak {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 100px;
    margin-left: -150px;
    margin-top: -50px;
    background-color: #0cdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 10px;
    font-size: 30px;
    box-shadow: 0 0 8px 0 #0b8f8f;
    z-index: 1000;
}

.version {
    position: absolute;
    top: 10px;
    left: 10px;
    color: gray;
    font-size: 10px;
}
</style>
