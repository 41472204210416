<template>
    <div class="tab" @click="$emit('select', tab.name)">
        <div class="tab-delete" @click="$emit('delete')">Х</div>
        <div>
            <div>{{ tab.title }}</div>
            <div class="rate" v-if="+tab.rate">{{ tab.rate }}$ / hour</div>
            <div>{{ time }}</div>
            <div class="money" v-if="+money">{{money}}$</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tab",
    props: {
        tab: {
            default: 'black'
        }
    },
    computed: {
        money() {
            return +(+this.tab.rate ? +this.tab.rate * (this.tab.time / 60 / 60) : 0).toFixed(2);
        },
        time() {
            return new Date(this.tab.time * 1000).toISOString().slice(11, 19);
        }
    }
}
</script>

<style lang="scss" scoped>
.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(25% - 15px);
    box-shadow: 0 0 12px 0 #dbdbdb;
    border-radius: 10px;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px;
    position: relative;
    margin-right: 15px;
    min-height: 130px;


    @media (min-width: 1024px) {

    }
    &:nth-child(4n+4) {
        margin-right: 0;
    }


    @media (max-width: 1024px) {
        width: calc(50% - 15px);
        font-size: 25px;
    }

    @media (max-width: 768px) {
        width: calc(50% - 15px);
        font-size: 23px;
    }

    @media (max-width: 576px) {
        width: calc(50% - 15px);
        font-size: 18px;
        height: 50px;
    }

    &.active {
        border: 3px solid #0cdfdf;
        background: #00e5e5;
        color: white;
        text-shadow: 0 0 4px #b56f6f;
    }

    .rate {
        font-size: 10px;
        color: #ccc;
    }

    .money {
        font-size: 12px;
        color: #ccc;
    }

    .tab-delete {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 16px;
        height: 16px;
        background: red;
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        box-sizing: border-box;
        font-size: 10px;
        cursor: pointer;
    }
}
</style>
